import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useContext, useState } from "react";
import { StyledGridRow } from "../../commons/Grid";
import { DataContext } from "../../contexts/DataContext";
import { Button } from "@3beehivetech/components";

import { 
  StyledBusinessCalculator,
  StyledBusinessCalculatorContent,
  StyledBusinessCalculatorTitle,
  StyledBusinessCalculatorSubtitle,
  StyledBusinessCalculatorInputs,
  StyledBusinessCalculatorInputsTitle,
  StyledBusinessCalculatorInputsItem,
  StyledBusinessCalculatorInputsItemIcon,
  StyledBusinessCalculatorInputsItemName,
  StyledBusinessCalculatorInputsItemSubtitle,
  StyledBusinessCalculatorResults,
  StyledBusinessCalculatorResultsItem,
  StyledBusinessCalculatorResultsItemLabel,
  StyledBusinessCalculatorResultsItemValue,
  StyledBusinessCalculatorDivider,
  StyledBusinessCalculatorButton,
} from "./style";
import useNavigate from "../../hooks/useNavigate";

const BusinessCalculator = () => {
  const { 
    labels, pageImages, languages,
  } = useContext(DataContext);
  const currentLanguage = languages.filter((language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "en";

  const [trees, setTrees] = useState(0);
  const [hives, setHives] = useState(0);

  const hive = pageImages.find(e => e.path === "/2020/images/business/calculator/hive.png");
  const tree = pageImages.find(e => e.path === "/2020/images/business/calculator/tree.png");

  const co2 = new Intl.NumberFormat(currentLanguageCode, { 
    roundingMode: "trunc", 
    notation: "compact",
    compactDisplay: "short", 
  }).format(2.75 * trees);
  const impollinator = new Intl.NumberFormat(currentLanguageCode, { 
    notation: "compact",
    compactDisplay: "short", 
  }).format(230000 * trees);
  const bees = new Intl.NumberFormat(currentLanguageCode, { 
    notation: "compact",
    compactDisplay: "short", 
  }).format(300000 * hives);
  const flowers = new Intl.NumberFormat(currentLanguageCode, { 
    notation: "compact",
    compactDisplay: "short", 
  }).format(300000000 * hives);

  return (
    <StyledGridRow isFullMobile>
      <StyledBusinessCalculator>
        <StyledBusinessCalculatorContent>
          <StyledBusinessCalculatorTitle
            dangerouslySetInnerHTML={{ __html: labels.calculator.title }}
          />
          <StyledBusinessCalculatorSubtitle
            dangerouslySetInnerHTML={{ __html: labels.calculator.subtilte }}
          />
          <StyledBusinessCalculatorInputsTitle
            dangerouslySetInnerHTML={{ __html: labels.calculator.inputs.title }}
          />
          <StyledBusinessCalculatorInputs>
            <BusinessCalculatorInput 
              name={labels.calculator.inputs.forest}
              subtitle={labels.calculator.inputs.forestSubtitle}
              icon={tree}
              value={trees}
              onChangeValue={(value) => {
                setTrees(value);
                window.dataLayer.push({
                  event: "BusinessCalculatorTapTrees",
                  value,
                });
              }}
            />
            <BusinessCalculatorInput 
              name={labels.calculator.inputs.hives}
              icon={hive}
              value={hives}
              onChangeValue={(value) => {
                setHives(value);
                window.dataLayer.push({
                  event: "BusinessCalculatorTapHives",
                  value,
                });
              }}
            />
          </StyledBusinessCalculatorInputs>
          <BusinessCalculatorResults 
            co2={`${co2} T`}
            impollinator={impollinator}
            bees={bees}
            flowers={flowers}
          />
        </StyledBusinessCalculatorContent>
        
        <StyledBusinessCalculatorButton>
          <Button 
            onClick={useNavigate("#contacts", () => {
              window.dataLayer.push({
                event: "BusinessCalculatorTapRequest",
              });
            })} 
            variant="green">
            {labels.calculator.button}
          </Button>
        </StyledBusinessCalculatorButton>
      </StyledBusinessCalculator>
    </StyledGridRow>
  );
};

const BusinessCalculatorInput = ({
  name, subtitle, icon, value, onChangeValue,
}) => {
  return (
    <StyledBusinessCalculatorInputsItem>
      <StyledBusinessCalculatorInputsItemIcon>
        <GatsbyImage style={{ width: "60px" }} image={getImage(icon)} alt={icon.alt} />
      </StyledBusinessCalculatorInputsItemIcon>
      <StyledBusinessCalculatorInputsItemName 
        dangerouslySetInnerHTML={{ __html: name }}
      />
      <BusinessCalculatorQuantitySelector 
        value={value} 
        onChangeValue={onChangeValue}
      />
      {subtitle && (
        <StyledBusinessCalculatorInputsItemSubtitle
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
    </StyledBusinessCalculatorInputsItem>
  );
};

const BusinessCalculatorResults = ({
  co2, impollinator, bees, flowers,
}) => {
  const { pageImages, labels } = useContext(DataContext);
  const bee = pageImages.find(e => e.path === "/2020/images/business/calculator/bee.png");
  const butterfly = pageImages.find(e => e.path === "/2020/images/business/calculator/butterfly.png");
  const cloud = pageImages.find(e => e.path === "/2020/images/business/calculator/cloud.png");
  const flower = pageImages.find(e => e.path === "/2020/images/business/calculator/flower.png");

  return (
    <StyledBusinessCalculatorResults className="flex bg-3bee-green-300 rounded-xl">
      <div className="flex">
        <BusinessCalculatorResultsItem 
          icon={cloud}
          label={labels.calculator.results.co2}
          value={co2}
        />
        <StyledBusinessCalculatorDivider/>
        <BusinessCalculatorResultsItem 
          icon={butterfly}
          label={labels.calculator.results.impollinators}
          value={impollinator}
        />
      </div>
      <StyledBusinessCalculatorDivider hideMobile/>
      <div className="flex">
        <BusinessCalculatorResultsItem 
          icon={bee}
          label={labels.calculator.results.protectedBees}
          value={bees}
        />
        <StyledBusinessCalculatorDivider/>
        <BusinessCalculatorResultsItem 
          icon={flower}
          label={labels.calculator.results.pollinatedFlowers}
          value={flowers}
        />
      </div>
    </StyledBusinessCalculatorResults>
  );
};

const BusinessCalculatorResultsItem = ({
  icon, label, value,
}) => {

  return (
    <StyledBusinessCalculatorResultsItem>
      <GatsbyImage style={{ width: "80px" }} image={getImage(icon)} alt={icon.alt} />
      <StyledBusinessCalculatorResultsItemLabel>
        {label}
      </StyledBusinessCalculatorResultsItemLabel>
      <StyledBusinessCalculatorResultsItemValue>
        {value}
      </StyledBusinessCalculatorResultsItemValue>
    </StyledBusinessCalculatorResultsItem>
  );
};

const BusinessCalculatorQuantitySelector = ({
  value, onChangeValue,
}) => {
  return (
    <div className="w-28 mx-auto my-0 ">
      <div className="box-border flex items-center justify-between px-2 w-25 h-7 bg-3bee-green-300 rounded-xl">
        <button
          className={`box-border flex items-center justify-center w-5 h-5 text-center text-white border-0 rounded-full bg-3bee-green-700 ${value > 0 ? "cursor-pointer" : "opacity-50"}`}
          onClick={() => onChangeValue(value > 0 ? value -= 1 : 0)}
        >
          -
        </button>
        <span className="w-12 text-base text-center">{value}</span>
        <button
          className={"box-border flex items-center justify-center w-5 h-5 text-center text-white border-0 rounded-full bg-3bee-green-700 cursor-pointer"}
          onClick={() => onChangeValue(value += 1)}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default BusinessCalculator;
